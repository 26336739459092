.authorize__block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;

  width: 366px;
  min-height: 423px;

  background-color: #eff6ff;
  border-radius: 20px;
}

.title {
  font-family: Onest, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #09306a;
}

.text__block {
  font-family: Onest, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.mail {
  font-family: Onest, sans-serif;
  font-weight: 700;
}

.forgot {
  font-family: Onest, sans-serif;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(9, 48, 106, 0.4);
}

.forgot:hover {
  text-decoration: underline;
}
