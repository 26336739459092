.NoCourses {
    margin: 0 auto;
    width: 305px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 123px;
    margin-bottom: 123px;
}

.cards{
    margin-bottom: 58.94px;
}

.text{
    font-family: Onest, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 136%; /* 19.04px */
    text-align: center;
    color: var(--main-color);
    margin-bottom: 35px;
}

.button{
    margin: 0 auto;
}