.ProgressBar {
    margin-bottom: 32px;
    height: 33px;
    width: 100%;
    border-radius: 26px;
    background-color: #EBF4FF;
    position: relative;
}
.progress{
    position: absolute;
    border-radius: 26px;
    background: linear-gradient(90deg, #3C77CE 0%, #08FDC2 100%);
    height: 33px;
    width: 100%;
    left: 0;
}

.text{
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1;
}