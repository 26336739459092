.pageCourse {
    margin-bottom: 117px;
}

.top__content{
    display: flex;
    margin-top: 20px;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 32px;
}

.status{
    width: 270px;
    height: 250px;
    background-color: #EBF4FF;
    border-radius: 25px;
    padding: 24px 40px;
    color: var(--main-color);
    font-family: Onest, sans-serif;
    display: flex;
    flex-direction: column;
}

.status__title{
    font-size: 24px;
    font-weight: 700;
    /* margin-bottom: 16px; */
    margin-bottom: auto;
    line-height: 1;
}

.status__descr{
    font-size: 14px;
    margin-bottom: 15px;
}

.content{
    display: flex;
    justify-content: start;
    gap: 30px;
    flex-wrap: wrap;
}

.buttons{
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 16px; */
}

.buttons__left{
    display: flex;
    gap: 8px;
}

.info{
    font-family: Onest, sans-serif;
    color: var(--main-color);
    width: 770px;
}

.info__title{
    font-size: 36px;
    font-weight: 800;
    /* width: 490px; */
    line-height: 1;
    margin-bottom: 32px;
}

.img{
    height: 275px;
    max-width: 770px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    margin-bottom: 32px;
}

.info__numbers{
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.info__card{
    height: 80px;
    width: 100%;
    border-radius: 25px;
    background-color: #EBF4FF;
    padding: 16px;
    text-align: center;
}

.card__title{
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 5px;
}

.descr{
    max-width: 666px;
    width: 100%;
    color: var(--main-color);
    font-family: Onest, sans-serif;
}

.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}