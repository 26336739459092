.card {
  /* width: 100%; */
  width: 827px;
  height: 215px;
  border-radius: 25px;
  padding: 20px 20px 38px 20px;
  background: rgba(0, 112, 255, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.sphere__icon {
  width: 24px;
  height: 24px;
}

.sphere__name {
  margin-left: 8px;
  width: 416px;

  margin-right: auto;
  color: #09306a;
  font-family: "Onest";
  font-size: 16px;
  font-weight: 500;
}

.sphere__date {
  color: #09306a;
  text-align: right;
  font-family: Onest;
  font-size: 14px;

  font-weight: 400;
  line-height: 136%;
}

.level__rang {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  color: #8ac540;
  font-family: Onest;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.level__icon {
  width: 32px;
  height: 32px;
}

.level__text {
  /* margin-left: 8px; */
}

.level__text_low {
  color: #ff7d1e;
}

.level__text_normal {
  color: #8ac540;
}

.level__text_high {
  color: #00a442;
}

.level__text_disabled {
  color: rgba(9, 48, 106, 0.4);
}

.level__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.level__wrapper:nth-child(2) {
  align-items: flex-end;
}

.level__rating {
  width: 23px;
  height: 54px;
  background-color: rgba(9, 48, 106, 0.4);
  opacity: 0.5;
}

.level__rating_normal {
  opacity: 1;
  background: #8ac540;
}

.loading {
  color: #09306a;
  font-family: Onest;
  font-size: 24px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
