.Dropdown{
    position: relative;
}

.DropdownButton {
    padding: 10.5px 44px 10.5px 24px;
    outline: 2px var(--main-color) solid;
    border: none;
    border-radius: 34px;
    background-color: #fff;
    color: var(--main-color);
    font-family: Onest, sans-serif;
    font-weight: 500;
    font-size: 16px;
    background-image: url('./img/arrow.svg');
    background-position: right 20px center;
    background-size: 12px 12px; /* Регулировка размера иконки */
    background-repeat: no-repeat;
    width: max-content;
    cursor: pointer;
    transition: 0.5s;
}

.DropdownButton:focus-visible{
    outline: 2px var(--main-color) solid;
}

.DropdownButton:hover{
    opacity: 88%;
}

.listbox{
    width: 270px;
    background-color: #ebf4fe;
    border-radius: 25px;
    position: absolute;
    top: 56.5px;
    right: 0;
    transition: 0.5s;
    opacity: 0;
    z-index: -1;
}

.list{
    list-style: none;
    padding: 12px 24px;
}

.listAnker{
    color: var(--main-color);
    font-family: Onest, sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.visible{
    opacity: 1;
    z-index: 10;
}

.DropdownOpen{
    outline: 2px #ebf4ff solid;
    background-color: #ebf4ff;
    background-image: url('./img/arrowUp.svg');
}