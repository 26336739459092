.Button {
  display: block;
  width: max-content;
  border: none;
  border-radius: 35px;
  padding: 12.5px 24px;
  text-decoration: none;
  color: var(--main-color);
  font-size: 16px;
  font-family: Onest, sans-serif;
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
  background-color: #fff;
  outline: var(--main-color) 2px solid;
}
.Button:hover {
  opacity: 88%;
}

.green {
  background-color: var(--green);
  outline: none;
}
.green:hover {
  opacity: 1;
  background-color: #79f7d8;
}

.blue,
.active {
  color: #fff;
  background-color: var(--main-color);
  outline: none;
}

.usual {
  background-color: #fff;
  outline: #09306a 2px solid;
}

.lightblue {
  outline: none;
  background-color: #eaf4ff;
}

.round {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 10.5px;
  background-color: var(--main-color);
  /* content: url("./img/arrow.svg"); */
  outline: none;
  background-image: url("./img/arrow.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.plus {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 10.5px;
  outline: 1px #9dacc3 solid;
  background-image: url("./img/plus.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.plus:hover {
  /* content: url("./img/arrowDown.svg"); */
  background-image: url("./img/arrowDown.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.up {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 10.5px;
  outline: 1px #9dacc3 solid;
  background-color: transparent;

  background-image: url("./img/up.svg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0s;
}
.down {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 10.5px;
  outline: 1px #9dacc3 solid;
  background-color: transparent;
  background-image: url("./img/down.svg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: 0s;
  /* content: url("./img/down.svg"); */
}

.w-100 {
  width: 100%;
}
