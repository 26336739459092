.form {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* .input {
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;

  font-family: Onest-Bold, serif;
  font-weight: 500;
  font-size: 16px;
  color: #09306a;
}

.input::placeholder {
  color: rgba(9, 48, 106, 0.4);
  font-weight: 500;
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.input__error {
  border: 1px solid #dd4b42;
  color: #dd4b42;
}

.input__wrapper {
  position: relative;
}

.password__eye {
  position: absolute;
  top: 12px;
  right: 14px;
  cursor: pointer;
} */

.button {
  margin-top: 19px;
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;
}

.error {
  margin: 12px 0 0 14px;
  font-family: Onest, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /* color: rgba(9, 48, 106, 0.4); */
  color: #dd4b42;
}
