.logo{
    display: flex;
    align-items: flex-end;
}
.logo__img{
    margin-right: 21.89px;
}
.logo__text{
    max-width: 344px;
    width: 100%;
    font-family: Onest, sans-serif;
    font-weight: 400;
    color: var(--main-color);
    font-size: 15.61px;
    text-decoration: none;
}