.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.reset__block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;

  width: 366px;
  /*   min-height: 423px; */
  background-color: hsl(214, 100%, 97%);
  border-radius: 20px;
}

.title {
  font-family: Onest, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #09306a;
}

.text__block {
  font-family: Onest, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.mail {
  font-family: Onest, sans-serif;
  font-weight: 700;
}

.back {
  font-family: Onest, sans-serif;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: rgba(9, 48, 106, 0.4);
}

.back:hover {
  text-decoration: underline;
}

.link {
  text-align: center;
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); 
  display: flex;
  align-items: center;

  justify-content: center;
}

.modal__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  width: 366px;
  background-color: hsl(214, 100%, 97%);
  border-radius: 20px;
  z-index: 1000;
}
