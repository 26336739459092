.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 134px;
}

.wrapper:nth-child(2) {
  gap: 0;
}

.card__info {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 370px;

  background-color: rgba(0, 112, 255, 0.08);
  border-radius: 20px;

  color: #09306a;
  font-family: "Onest";
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.card__info > p {
  word-wrap: break-word;
}

.tabs {
  display: flex;
  gap: 8px;
  max-width: 870px;
  flex-wrap: wrap;
}

.tabs__button {
  /* width: 102px; */
  margin-bottom: 0;
}

.spheres__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 827px;
}

/* СЕССИИ СТРАНИЦА */

.session__type {
  display: flex;
  align-items: center;
  gap: 24px;
}

.session__title {
  color: var(--text-primary, #09306a);
  font-family: Onest;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.session__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.session__button {
  width: 32px;
  height: 32px;
}

/* .loading {
  color: #09306a;
  font-family: Onest;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
} */

.session__empty {
  color: #09306a;
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
