.allCourses {
  margin-bottom: 117px;
}

.input {
  width: 274px;
}

.search {
  display: flex;
  gap: 12px;
  margin-top: 40px;
}

.filters {
  display: flex;
  gap: 8px;
  margin-bottom: 40px;
}

.reset {
  display: flex;
  align-items: center;

  text-align: center;

  font-family: Onest;
  font-size: 16px;

  font-weight: 500;
  color: rgba(9, 48, 106, 0.4);
  margin-bottom: 24px;
}

.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .SheetContainer{
  position: relative;
  height: 141px;
} */
