.CardTask {
  width: 270px;
  min-height: 270px;
  height: 100%;
  background-color: #ebf4ff;
  padding: 24px 16px;
  border-radius: 25px;
  font-family: Onest, serif;
  color: var(--main-color);
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  font-size: 16px; /* ПОДПРАВИТЬ для сессий  */
}
.CardTask:hover {
  background-color: #e1edff;
}

.title {
  font-weight: bold;
  /* margin-bottom: 12px; */
}

.descr {
  /* margin-top: 12px; */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.disabled {
  opacity: 0.5;
  cursor: unset;
}
.disabled:hover {
  background-color: #ebf4ff;
}

.active {
  background-color: #c7e0ff;
  cursor: unset;
}
.active:hover {
  background-color: #c7e0ff;
}

.lockImg {
  position: absolute;
  right: 16px;
  bottom: 24px;
}

/* ПОДПРАВИТЬ для сессий  */
.date {
  color: #09306a;

  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%; /* 19.04px */
}

.button {
  margin-top: auto;
  width: 100%;
}

.endDate {
  /*   margin-top: auto; */
  text-align: center;

  font-size: 14px;

  span {
    font-weight: bold;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
