.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorize {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.authorize__block {
  margin: 0 auto;
  /*   padding: 0 135px; */
}


