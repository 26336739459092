.form {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button {
  margin-top: 19px;
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
}

.error {
  margin: 12px 0 0 0;
  font-family: Onest-Medium, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /* color: rgba(9, 48, 106, 0.4); */
  color: #dd4b42;
  text-align: center;
}
