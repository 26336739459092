.input {
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;
  position: relative;

  font-family: Onest, serif;
  font-weight: 500;
  font-size: 16px;
  color: #09306a;
  background: rgba(0, 112, 255, 0.08);
}

.input__white {
  background-color: #fff;
}

.input::placeholder {
  color: rgba(9, 48, 106, 0.4);
  font-weight: 500;
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.input__error {
  border: 1px solid #dd4b42;
  color: #dd4b42;
}

.input__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.eyeIcon {
  position: absolute;
  top: 12px;
  right: 14px;
  cursor: pointer;
}

/* Костыль */
.eyeIcon__profile {
  top: 38px;
}

.error {
  margin: 12px 0 0 14px;
  font-family: Onest, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /*   color: rgba(9, 48, 106, 0.4); */
  color: #dd4b42;
}

.label {
  margin-bottom: 8px;
  color: #09306a;
  font-family: Onest, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 136%;
}
