.navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.5px 0;
    margin-bottom: 40px;
}

.navigation__ul{
    display: flex;
    justify-content: space-between;
}

.navigation__li{
    list-style: none;
    margin-right: 28px;
}

.navigation__a{
    text-decoration: none;
    font-size: 16px;
    color: var(--main-color);
    font-family: Onest;
    font-weight: 500;
}

.navigation__dashboard{
    display: flex;
    align-items: center;
}