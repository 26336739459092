.Card {
    width: 370px;
    padding: 16px;
    background-color: #EFF6FF;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    min-height: 487px;
    position: relative;
    height: 100%;
}

.Card__img{
    border-radius: 25px;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    margin-bottom: 16px;
    height: 200px;
}

.Card__chips{
    margin-bottom: 16px;
}

.Card__text{
    margin-bottom: 24px;
}

.Card__title{
    font-family: Onest, serif;
    font-weight: 700;
    font-size: 24px;
    color: var(--main-color);
    margin-bottom: 12px;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Card__descr{
    font-family: Onest, serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--main-color);
    line-height: 19.04px;

    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.Card__bottom{
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: space-between;
    margin-top: auto;
}

.Card__calendar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Card__time{
    font-size: 18px;
    font-family: Onest, serif;
    font-weight: 700;
    color: var(--main-color);
    margin-left: 8px;
}