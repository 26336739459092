.select {
  display: flex;
  flex-direction: column;
}

.select label {
  margin-bottom: 8px;
}

.dropdown {
  width: 100%;
  /* padding: 14px 12.5px; */

  font-family: "Onest", serif;
  font-weight: 500;
  font-size: 16px;
  color: #09306a;
  background: rgba(0, 112, 255, 0.08);
  border-radius: 10px;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown::placeholder {
  color: rgba(9, 48, 106, 0.4);
  font-weight: 500;
  font-size: 16px;
}

.dropdown__wrapper {
  width: 100%;
  padding: 14px 12.5px;
  position: relative;
}

.dropdown__button {
  width: 100%;
  text-align: left;
  font-family: "Onest", serif;
  font-weight: 500;
  font-size: 16px;
  color: #09306a;
  transition: 0.3s;
}

.dropdown__button::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background-image: url("./img/arrow.svg");
  transition: 0.3s;
}

.dropdown__button_active::after {
  transition: 0.3s;
  transform: translateY(-50%) rotate(180deg);
}

.dropdown__list {
  display: none;
}

.dropdown__list_visible {
  list-style-type: none;
  display: block;
  width: 100%;

  /* background: rgba(0, 112, 255, 0.08); */
  border-radius: 0 0 10px 10px;
}

.dropdown__item {
  cursor: pointer;
  padding: 11px 14px;
}

.dropdown__item:hover {
  background: rgba(0, 112, 255, 0.08);
}

.dropdown__item:last-child {
  border-radius: 0 0 10px 10px;
}

.label {
  margin-bottom: 8px;
  color: #09306a;
  font-family: "Onest", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 136%;
}

/* Стилизация скроллбара для Webkit-браузеров (Chrome, Safari) */
.dropdown {
  scrollbar-width: none; /* Firefox */
}

/* Chrome, Safari, Edge */
.dropdown:-webkit-scrollbar {
  width: none; /* Ширина скроллбара */
}

.error {
  margin: 12px 0 0 14px;
  font-family: Onest, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /*   color: rgba(9, 48, 106, 0.4); */
  color: #dd4b42;
}

.select__error {
  border: 1px solid #dd4b42;
}

.select__placeholder {
  color: rgba(9, 48, 106, 0.4);
  font-weight: 500;
  font-size: 16px;
}
