.Tab {
  width: max-content;
  border-radius: 35px;
  border: none;
  padding: 12.5px 24px;
  text-decoration: none;
  color: var(--main-color);
  font-size: 16px;
  font-family: Onest, sans-serif;
  font-weight: 500;
  transition: 0.5s;
  cursor: pointer;
  background-color: #ebf4ff;
  margin-bottom: 24px;
}

.quantity {
  font-family: Onest, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
}

.blue,
.active {
  color: #fff;
  background-color: var(--main-color);
  outline: none;
}

.select {
  border-radius: 25px;
  background: rgba(0, 112, 255, 0.08);
}

.arrow {
  margin-left: 9px;
}

.disabled {
  background-color: #fff;
}
