.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorize {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

/* .authorize__block {
  margin: 0 auto;
  /*   padding: 0 135px; */

.authorize__block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;

  width: 366px;
  /*   min-height: 423px; */
  background-color: hsl(214, 100%, 97%);
  border-radius: 20px;
}

.title {
  font-family: Onest, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #09306a;
}

.text {
  font-family: Onest, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
