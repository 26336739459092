.form {
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* .input {
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;

  font-family: Onest-Bold, serif;
  font-weight: 500;
  font-size: 16px;
  color: #09306a;
}

.input::placeholder {
  color: rgba(9, 48, 106, 0.4);
  font-weight: 500;
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.input__error {
  border: 1px solid #dd4b42;
  color: #dd4b42;
}

.input__wrapper {
  position: relative;
}

.password__eye {
  position: absolute;
  top: 12px;
  right: 14px;
  cursor: pointer;
} */

.button {
  margin-top: 19px;
  padding: 11px 14px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;
}

.error {
  margin: 12px 0 0 14px;
  font-family: Onest, serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 136%;
  /* color: rgba(9, 48, 106, 0.4); */
  color: #dd4b42;
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;

  justify-content: center;
  z-index: 1;
}

.modal__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  width: 366px;
  background-color: hsl(214, 100%, 97%);
  border-radius: 20px;
  z-index: 1000;
}

.descr {
  font-family: Onest, serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-color);
  line-height: 19.04px;
}

.title {
  font-family: Onest, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #09306a;
}

.back {
  font-family: Onest, sans-serif;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: rgba(9, 48, 106, 0.4);
}

.back:hover {
  text-decoration: underline;
}

.email {
  font-family: Onest, sans-serif;
  font-weight: 700;
}

.message {
  font-family: Onest, sans-serif;
  font-size: 16px;
  background: none;
  border: none;
  font-weight: 500;
  color: rgba(9, 48, 106, 0.4);
  text-align: center;
}

.modal__btn {
  margin-top: 0;
}
