.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 134px;
}

.card__info {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 370px;
  /*   position: relative; */

  background-color: rgba(0, 112, 255, 0.08);
  border-radius: 20px;

  color: #09306a;
  font-family: "Onest";
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.card__info > p {
  word-wrap: break-word;
}

.tabs {
  display: flex;
  gap: 8px;
}

.tabs__button {
  width: 102px;
}

/* Гриды какие-то костыльные, нужно над ними подумать */
.form__inputs {
  display: grid;
  grid-template-columns: repeat(3, 375px);
  gap: 30px;
}
.input__item:nth-child(12),
.input__item:nth-child(13),
.input__item:nth-child(14) {
  grid-column: 1 / 3;
  width: 375px;
}

.form {
  position: relative;
}

.form__button {
  margin-top: 40px;
  width: 195px;
}

.form__password_inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input__password {
  width: 342px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
