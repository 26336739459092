.Footer {
    background-color: #EFF6FF;
    padding-top: 24px;
    padding-bottom: 39px;
    border-radius: 50px 50px 0 0;
    min-width: 800px;
}

.Footer__top{
    display: flex;
    justify-content: space-between;
}

.Footer__bottom{
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
}

.social{
    margin-top: 21px;
}

.social__title{
    font-size: 18px;
    font-family: Onest, sans-serif;
    font-weight: 700;
    color: var(--main-color);
    margin-bottom: 15px;
}

.social__items{
    display: flex;
    justify-content: flex-end;
}

.social__icon{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 12px;
}

.company{
    font-size: 16px;
    font-family: Lora, serif;
    font-weight: 400;
    color: var(--main-color);
}
.politic{
    font-size: 16px;
    color: var(--main-color);
    font-family: Onest, serif;
    font-weight: 500;
    text-decoration: none;
}

.telegram{
    background-color: #2BCCFF;
}
.vk{
    background-color: #5D8CE8;
}
.rutube img{
    width: 32px;
    height: 32px;
}
