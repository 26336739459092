.Chip {
  width: max-content;
  padding: 5px 20px;
  border-radius: 25px;
  color: var(--main-color);
  font-family: Onest, sans-serif;
  font-weight: 500;
  outline: 2px solid var(--main-color);
  background-color: #fff;
  height: 30px;
  font-size: 16px;
  display: flex;
}

.green {
  outline: none;
  background-color: var(--green);
}

.level_low {
  outline: none;
  /*   background: #ff7d1e; */
  background: #ffab6f;
}

.level_normal {
  outline: none;
  background: #8ac540;
}

.level_high {
  outline: none;
  background: #00a442;
}

.disabled {
  outline: none;
  color: #9dacc3;
  border: 1px solid #9dacc3;
  display: flex;
  align-items: center;
}

.check {
  color: #09306a;
  outline: none;
  border: none;
  background-color: #ebf4fe;
}
.check:before {
  content: url("./img/check.svg");
  margin-right: 12.67px;
}

.absolute {
  position: absolute;
  top: 31px;
  left: 32px;
}

.quantity {
  font-family: Onest, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
}

.unactive {
  cursor: unset;
}
