.SheetContainer{
    height: 141px;
    display: flex;
    justify-content: space-between;
}

.SheetCover{
    width: 100%;
    max-width: 568px;
}

.Sheet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    background-color: #ebf4ff;
    margin-bottom: 40px;
    align-items: end;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.dropdownContent{
    width: 100%;
    max-width: 568px;
}

.Sheet__lecture{
    width: 100%;
    padding: 25.5px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Sheet__lecture:nth-child(odd){
    background-color: #E1EDFF;
}

.Sheet__lecture:first-child{
    background-color: #ebf4ff;
}

.Sheet__lecture2{
    height: 101px;
}

.Sheet__title{
    font-family: Onest, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 19.8px;
    color: var(--main-color);
    max-width: 260px;
    width: 100%;
    margin-bottom: 9px;
    line-height: 1;
}

.Sheet__title2{
    max-width: unset;
    margin-bottom: 0;
}

.shortName{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: unset;
}

.Sheet__line{
    height: 5px;
    width: 260px;
    background-color: #91A6C3;
    position: relative;
}

.Sheet__progress{
    position: absolute;
    left: 0;
    height: 5px;
    max-width: 260px;
    background-color: var(--main-color);
}

.spinner{
    height: 141px;
    max-width: 568px;
    width: 100%;
    display: flex;
    justify-content: center;
}