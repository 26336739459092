* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  --main-color: #09306a;
  --green: #08fdc2;
}

main {
  flex-grow: 1;
  padding-top: 20px;
  /* padding: 20px 0; */
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

@font-face {
  font-family: Onest;
  src: url(./fonts/Onest/Onest-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Onest;
  src: url(./fonts/Onest/Onest-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Onest;
  src: url(./fonts/Onest/Onest-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Lora;
  src: url(./fonts/Lora/Lora-Regular.ttf);
  font-weight: 400;
}

.cards {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.flex {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
