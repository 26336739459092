.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 121px;
}

.level__wrapper {
  display: flex;
  gap: 20px;
}

.level__rang {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Onest;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.level__wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.level__desc {
  white-space: pre-wrap;
  max-width: 570px;
  font-size: 16px;
  font-weight: 500;
}

.level__icon {
  width: 32px;
  height: 32px;
}

.level__text_low {
  color: #ff7d1e;
}

.level__text_normal {
  color: #8ac540;
}

.level__text_high {
  color: #00a442;
}

.level__rating {
  width: 50px;
  height: 50px;
  background-color: rgba(9, 48, 106, 0.4);
  opacity: 0.5;
}

.level__rating__wrapper {
  display: flex;

  gap: 20px;
  align-items: flex-end;
}

.level__rating__card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  max-width: 76px;
  gap: 20px;
}

.date {
  color: rgba(9, 48, 106, 0.4);
  font-family: Onest;
  font-size: 14px;
  line-height: 136%; /* 19.04px */
}

.date__last {
  color: #09306a;
}

.courses__title {
  color: #09306a;
  font-family: Onest;
  font-size: 24px;

  font-weight: 700;
  line-height: 110%;
}

.courses__wrapper {
  margin-top: 20px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
