.gradeSession {
  font-family: Onest, sans-serif;
  color: var(--main-color);
  padding-bottom: 249px;
}

.title {
  font-weight: 800;
  font-size: 36px;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 1;
}

.descr {
  width: 570px;
  margin-bottom: 40px;
}

.content {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}
